import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageErrorString from 'Utils/PageErrorString'
import sentryService from 'Services/sentryService'
import globalsService from 'Services/globalsService'
import isCustomDomain from 'Utils/isCustomDomain'
import GetGlobals from 'Utils/GetGlobals'
import GetSubdomainFromHost from 'Utils/GetSubdomainFromHost'
import PageHead from 'Components/PageHead'
import { useRouter } from 'next/router'
import logger from 'Utils/logger'
import Lazyload from 'Components/Lazyload'
import LoginAndContinue from 'Utils/LoginAndContinue'
import ViewCore from './ViewCore'

const StatusCodeError = Lazyload(() => import('Components/StatusCodeError'))
const PageSpinner = Lazyload(() => import('Components/PageSpinner'))

const LOGIN_REQUIRED_MESSAGE = 'Login required'

let firstLoad = true

const Layout = (WrappedComponent, options) => {
  const {
    sidebarVariant,
    isFooterPresent,
    topNavPresent = true,
    fullWidth = false,
    customTopNav = null,
    loginRequired = false,
    noPadding = false,
  } = options
  function HOC(props) {
    const { getYlurnInitialProps, getInitialProps } = WrappedComponent

    // props important, for passing resolves to child component
    const ylurnInitPropsRequired = Boolean(getYlurnInitialProps)
    const [ylurnInitProps, setYlurnInitProps] = useState(null)
    const [yErrorMessage, setYErrorMessage] = useState('')
    const [yStatusCode, setYStatusCode] = useState(null)
    const [loadingYlurnPropsStatus, setLoadingYlurnPropsStatus] = useState(
      'loading'
    )
    const { statusCode, appErrorMessage, initGlobalsSet, globals } = props
    const [localGlobals, setLocalGlobals] = useState(globals)
    const isUserLoggedIn = Boolean(globalsService.getLoggedUser())
    const router = useRouter()

    // globals will be passed as props for ssred pages
    // set globals using the same on client side on first load
    if (
      firstLoad &&
      getInitialProps &&
      globals &&
      globals.school &&
      typeof window !== 'undefined'
    ) {
      globalsService.set(globals)
      firstLoad = false
    }

    // router wont have query params before hydration
    // on pre rendered pages https://github.com/vercel/next.js/issues/8259#issuecomment-650225962
    const hasQueryParams =
      /\[.+\]/.test(router.route) || /\?./.test(router.asPath)
    const isRouterReady =
      !hasQueryParams || Object.keys(router.query).length > 0

    // for non getInitialProps globals set will be false
    const [globalsSet, setGlobalsSet] = useState(initGlobalsSet)

    useEffect(() => {
      // set globalsset for non getinitalprop pages
      GetGlobals().then(
        (fetchedGlobals) => {
          setLocalGlobals(fetchedGlobals)
          setGlobalsSet(true)
        },
        () => {}
      )
    }, [])

    useEffect(() => {
      // post globals set handler
      if (!globalsSet) return

      // if login required prop is set and user does not exist
      // use loginandcontinue
      if (loginRequired && !isUserLoggedIn) {
        LoginAndContinue()
        return
      }

      if (getYlurnInitialProps && isRouterReady) {
        // pass router object to client props method
        getYlurnInitialProps(router).then(
          (data) => {
            setYlurnInitProps(data)
          },
          (err) => {
            const message = PageErrorString(err)
            if (message === LOGIN_REQUIRED_MESSAGE) {
              // if ylurn loading props need login
              // then redirect to dashboard and open modal
              LoginAndContinue()
            } else {
              logger.log({
                uncaught_getylurninitial_props_error: err,
              })
              let newErrorCode = err ? err.status : null
              if (!yStatusCode) {
                // not api response code, some client side error
                sentryService.captureException(err)
                newErrorCode = 500
              }
              setYStatusCode(newErrorCode)
              setYErrorMessage(message)
              setLoadingYlurnPropsStatus('errored')
            }
          }
        )
      }
    }, [globalsSet, isRouterReady])

    useEffect(() => {
      if (ylurnInitProps) {
        // add a check because its triggered on first run (null)
        // using use effect so that ylurnInitProps are in state
        // when status is set to success
        setLoadingYlurnPropsStatus('success')
      }
    }, [ylurnInitProps])

    return (
      <ViewCore
        isFooterPresent={isFooterPresent}
        sidebarVariant={sidebarVariant}
        topNavPresent={topNavPresent}
        fullWidth={fullWidth}
        pageProps={{ ...props, ...ylurnInitProps }}
        customTopNav={customTopNav}
        noPadding={noPadding}
      >
        {isRouterReady && globalsSet && (!loginRequired || isUserLoggedIn) ? (
          <div>
            {
              // populate school meta tags on mount for all pages
              localGlobals && localGlobals.school && (
                <PageHead
                  title={localGlobals.school.name}
                  school={localGlobals.school}
                />
              )
            }
            {props && statusCode ? (
              <StatusCodeError
                statusCode={statusCode}
                appErrorMessage={appErrorMessage}
              />
            ) : (
              <>
                {ylurnInitPropsRequired ? (
                  <>
                    {loadingYlurnPropsStatus === 'loading' && <PageSpinner />}
                    {loadingYlurnPropsStatus === 'errored' && (
                      <StatusCodeError
                        statusCode={yStatusCode}
                        appErrorMessage={yErrorMessage}
                      />
                    )}
                    {loadingYlurnPropsStatus === 'success' && (
                      <WrappedComponent {...props} {...ylurnInitProps} />
                    )}
                  </>
                ) : (
                  <WrappedComponent {...props} />
                )}
              </>
            )}
          </div>
        ) : (
          // if login is required and user is not logged in
          // show blank
          <div />
        )}
      </ViewCore>
    )
  }
  if (WrappedComponent.getInitialProps) {
    HOC.getInitialProps = async (ctx) => {
      let globals = { cookies: {} }

      // first set of code executed at server because getinitalprops
      // of _app.js has been removed

      // if server, set school slug/custom domain
      // will be used by api util

      const isServer = ctx.res && ctx.req
      let initGlobalsSet = false

      if (isServer) {
        const {
          req: {
            headers: { host },
          },
        } = ctx
        // use host to get slug/custom-domain
        const school = {}
        if (isCustomDomain(host)) school.customDomain = host
        else {
          const slug = GetSubdomainFromHost(host)
          school.slug = slug
        }
        globalsService.set({ school })
      }

      let pageProps = {}
      try {
        // this will check and also update globals with proper
        // cookies, should be called before page props/getinitialprops
        globals = await GetGlobals(ctx.req)
        initGlobalsSet = Boolean(globals)
        pageProps = await WrappedComponent.getInitialProps(ctx)
        // check login only after globals have been populated
        if (loginRequired) LoginAndContinue(ctx)
      } catch (err) {
        const errorMessage = PageErrorString(err)
        if (errorMessage === LOGIN_REQUIRED_MESSAGE) {
          LoginAndContinue(ctx)
        } else {
          logger.log({
            appjs_uncaught_getinitial_props_error: err,
          })
          let statusCode = err ? err.status : null
          if (!statusCode) {
            // not api response code, some client side error
            sentryService.captureException(err)
            statusCode = 500
          }
          pageProps = {
            statusCode,
            appErrorMessage: errorMessage,
            initGlobalsSet,
          }
        }
      }
      return { ...pageProps, initGlobalsSet, globals }
    }
  }

  HOC.defaultProps = {
    statusCode: undefined,
    appErrorMessage: undefined,
    initGlobalsSet: false,
    globals: { cookies: {} },
  }

  HOC.propTypes = {
    statusCode: PropTypes.any,
    appErrorMessage: PropTypes.any,
    initGlobalsSet: PropTypes.any,
    globals: PropTypes.any,
  }

  return HOC
}

Layout.propTypes = {
  sidebarVariant: PropTypes.oneOf(['HOME', 'COURSE', 'NONE']).isRequired,
  isFooterPresent: PropTypes.bool,
}

export default Layout
