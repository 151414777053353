import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sentryService from 'Services/sentryService'
import Lazyload from 'Components/Lazyload'
import logger from 'Utils/logger'

const MAX_CONTAINER_WIDTH = 1250

const SideNavDrawer = Lazyload(() => import('./SideNavDrawer'))
// sidenav loading placeholder is same width to avoid flicker
const Footer = Lazyload(() => import('./Footer'))
const TopNav = Lazyload(() => import('./TopNav'))
const ApplicationError = Lazyload(() => import('Components/ApplicationError'))

// on height and scroll changes of view component
// test 3 things
// if long text pages and footer are proper
// if dynamic long text pages and scroll are proper
// if no content pages have sufficent height for footer

class ViewCore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      errored: false,
    }
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    this.closeMobileSidenav = this.closeMobileSidenav.bind(this)
  }

  static getDerivedStateFromError(error) {
    logger.log({ viewcore_error: error })
    sentryService.captureException(error)
    // Update state so the next render will show the fallback UI.
    return { errored: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    sentryService.captureException(error)
    logger.log({ viewcore_error: error, info })
  }

  handleDrawerToggle() {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }))
  }

  closeMobileSidenav() {
    this.setState({ mobileOpen: false })
  }

  render() {
    const {
      isFooterPresent,
      sidebarVariant,
      children,
      pageProps,
      topNavPresent,
      fullWidth,
      noPadding,
    } = this.props

    const { mobileOpen, errored } = this.state

    const sidebarPresent =
      sidebarVariant !== 'NONE' && sidebarVariant !== 'LANDING'
    // used to control width of content area for non mobile screens

    return (
      <div id="ylurnPageContainer" className="bg-white">
        <main>
          <>
            {errored && <ApplicationError />}
            {!errored && (
              <div>
                {/* holds left and right parts together */}
                <div style={{ display: 'flex' }}>
                  {sidebarPresent && (
                    <div className="block" style={{ minHeight: '100vh' }}>
                      <div>
                        {/* hidden part added to avoid flicker during ssr */}
                        <div className="hidden lg:block">
                          {sidebarPresent && (
                            <div className="w-screen max-w-xs" />
                          )}
                        </div>
                        <SideNavDrawer
                          sidebarVariant={sidebarVariant}
                          mobileOpen={mobileOpen}
                          handleDrawerToggle={this.handleDrawerToggle}
                          pageProps={pageProps}
                          closeMobileSidenav={this.closeMobileSidenav}
                        />
                      </div>
                    </div>
                  )}
                  {sidebarVariant === 'LANDING' && (
                    <SideNavDrawer
                      sidebarVariant={sidebarVariant}
                      mobileOpen={mobileOpen}
                      handleDrawerToggle={this.handleDrawerToggle}
                      pageProps={pageProps}
                      closeMobileSidenav={this.closeMobileSidenav}
                    />
                  )}
                  <div className="mainArea overflow-auto bg-gray-100 flex-1">
                    {topNavPresent && (
                      <div
                        className="lg:right-0 w-full h-16 lg:h-20"
                        style={{ zIndex: 1201 }}
                      >
                        <TopNav
                          handleDrawerToggle={this.handleDrawerToggle}
                          sidebarVariant={sidebarVariant}
                          pageProps={pageProps}
                          fullWidth={fullWidth}
                        />
                      </div>
                    )}
                    <div
                      style={{ minHeight: 'calc(100vh - 100px)' }}
                      // controls background color of always fullwidth background
                      className="bg-gray-100"
                    >
                      <div
                        className={`overflow-x-auto ${
                          noPadding ? 'p-0' : 'p-0 lg:py-4 lg:px-8'
                        }`}
                        // controls width of content
                        style={{
                          minHeight: 'inherit',
                          maxWidth: fullWidth
                            ? null
                            : `${MAX_CONTAINER_WIDTH}px`,
                          marginLeft: sidebarPresent ? null : 'auto',
                          marginRight: sidebarPresent ? null : 'auto',
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  </div>
                  {/* footer is always full width - check footer comp styles */}
                </div>
                {isFooterPresent && <Footer />}
              </div>
            )}
          </>
        </main>
      </div>
    )
  }
}

ViewCore.propTypes = {
  sidebarVariant: PropTypes.string,
  isFooterPresent: PropTypes.bool.isRequired,
  topNavPresent: PropTypes.bool.isRequired,
  pageProps: PropTypes.object,
  fullWidth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool.isRequired,
}

ViewCore.defaultProps = {
  sidebarVariant: null,
  pageProps: null,
}

export default ViewCore
