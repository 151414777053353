import globalsService from 'Services/globalsService'
import ModifyUrlParam from 'Utils/ModifyUrlParam'
import Router from 'next/router'
import OpenLoginDialog from 'Utils/OpenLoginDialog'

export default function LoginAndContinue(ctx) {
  // optional takes next.js' context object as input (pages using getinitialprops)

  if (globalsService.getLoggedUser()) {
    // if user is logged then do nothing and return
    return
  }

  // not logged needs some action/redirect

  if (ctx) {
    const { res, req } = ctx
    if (req && res) {
      // req, res only available on server
      let redirectUrl = req.url
      // force strip modal and redirectUrl to avoid infinite loop in query params
      redirectUrl = ModifyUrlParam(redirectUrl, 'modal', null)
      redirectUrl = ModifyUrlParam(redirectUrl, 'redirectUrl', null)
      // redirectUrl cleaned (no modal and redirectUrl)
      let url = '/courses'
      if (redirectUrl === '/courses') {
        // can happen say if 401 was returned from notification bell API call
        // if login required was called on /courses
        // using redirect to /logout
        // cant cause redirect loop
        url = '/logout'
      }
      url = ModifyUrlParam(url, 'modal', 'login')
      url = ModifyUrlParam(url, 'redirectUrl', redirectUrl)
      res.writeHead(302, {
        Location: url,
      })
      res.end()
      // TODO: redirect flow when auth is expired and user
      // hits a login required page
    } else {
      const redirectUrl = ctx.asPath // combination of path and query parameters
      OpenLoginDialog(redirectUrl)
    }
  } else {
    // used inside getYlurnInitialProps (client side no ssr)
    const redirectUrl = window.location.pathname
    Router.push(`/courses?modal=login&redirectUrl=${redirectUrl}`)
  }
}
