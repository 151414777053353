import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

// has dynamic/variable part of the head

const PageHead = ({ title, description, card, school }) => (
  <Head>
    {title && <title>{title}</title>}
    {description && <meta name="description" content={description} />}
    {
      // add open graph meta tags.
      // see https://ogp.me/#types for types of og.
      card && (
        <>
          <meta property="og:title" content={card.title} />
          <meta property="og:type" content={card.type} />
          <meta property="og:url" content={card.url} />
          <meta property="og:image" content={card.image} />
          {card.description && (
            <meta property="og:description" content={card.description} />
          )}
        </>
      )
    }
    {
      // add school tags
      school && (
        <>
          {school.squareLogo && (
            <link rel="shortcut icon" href={school.squareLogo} />
          )}
          {school.googleSiteVerification && (
            <meta
              name="google-site-verification"
              content={school.googleSiteVerification}
            />
          )}
          <meta name="author" content={school.name} />
        </>
      )
    }
  </Head>
)

PageHead.defaultProps = {
  title: '',
  description: '',
  card: null,
  school: null,
}

PageHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  card: PropTypes.object,
  school: PropTypes.any,
}

export default PageHead
