import Router from 'next/router'
import ModifyUrlParam from 'Utils/ModifyUrlParam'

/*
Opens up the login dialog, ensure that its not called like onClick={OpenLoginDialog}
as that will pass an event object as first param when its expecting a url (string)
*/

const OpenLoginDialog = (redirectUrl) => {
  const { asPath, pathname, query } = Router.router
  query.redirectUrl = redirectUrl
  query.modal = 'login'
  let url = asPath
  url = ModifyUrlParam(url, 'redirectUrl', redirectUrl)
  url = ModifyUrlParam(url, 'modal', 'login')
  Router.push({ pathname, query }, url, { shallow: true })
}

export default OpenLoginDialog
